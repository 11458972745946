import React from 'react';
import { Image } from 'react-bootstrap';

import Content from '../../Content';

const CommunityContent = () => {
  const content = (
    <div>
      <Content
        left
        title="Functional modules"
        text="HumanBase applies community detection to find cohesive gene clusters from a provided gene list and a selected relevant tissue. Genes within a cluster share local network neighborhoods and together form a cohesive, specific functional module."
        image={
          <Image
            src="/community/communities.png"
            responsive
            alt="functional modules"
          />
        }
      />
      <Content
        left={false}
        title="Gene function prediction"
        text="Module detection enables systematic association of genes - even functionally uncharacterized genes - to specific processes and phenotypes represented in the detected modules."
        image={
          <Image
            src="/community/predictions.png"
            responsive
            alt="module biological processes"
          />
        }
      />
      <Content
        left
        title="Tissue-specific"
        text="Functional modules are identified with tissue-specific networks, which predict gene interactions from massive data collections. Thus the discovered modules potentially capture higher-order tissue-specific function."
        image={
          <Image
            src="/brain-network.png"
            responsive
            height="300px"
            alt="brain tissue-specific network"
          />
        }
      />
    </div>
  );
  return content;
};

export default CommunityContent;
