import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'react-bootstrap';

import s from './Content.css';

export class Content extends Component {
  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.element,
    children: PropTypes.element,
    left: PropTypes.bool,
  };

  static defaultProps = {
    text: '',
    title: '',
    left: true,
    image: null,
    children: null,
  };

  render() {
    const { title, text, image, left, children } = this.props;

    const contentText = (
      <Col md={7} mdOffset={left ? 1 : null} sm={9} xs={10}>
        <div className={s.content}>
          <h1>{title}</h1>
          <p className={s.contentLead}>{text}</p>
          {children}
        </div>
      </Col>
    );

    const contentImage = (
      <Col md={3} sm={3} xs={2} mdOffset={left ? null : 1}>
        {image}
      </Col>
    );

    const leftPanel = left ? contentText : contentImage;
    const rightPanel = left ? contentImage : contentText;

    return (
      <Row className={s.contentRow}>
        {leftPanel}
        {rightPanel}
      </Row>
    );
  }
}

export default withStyles(s)(Content);
