import React from 'react';
import { Image } from 'react-bootstrap';

import Link from '../Link';
import Content from '../Content';
import NetworkContentItems from '../Content/NetworkContentItems';

function NetworkContent() {
  const content = (
    <div>
      <NetworkContentItems />
      <Content
        left
        title="Network-guided"
        text="Tissue-specific networks can guide genome-wide association (GWAS) analysis by effectively reprioritizing the associations from a GWAS study. With NetWAS (Network-guided GWAS Analysis), HumanBase can aide researchers in identifying additional disease-associated genes."
        image={
          <Image
            src="/dr_gwas1.jpg"
            responsive
            style={{ marginTop: '30px' }}
            alt="genome-wide assocation studies"
          />
        }
      >
        <Link to="/netwas">
          NetWAS analysis <i className="fa fa-arrow-right" />
        </Link>
      </Content>
    </div>
  );
  return content;
}

export default NetworkContent;
