import React from 'react';
import { Image } from 'react-bootstrap';

import Content from './Content';
import Link from '../Link';

const NetworkContentItems = () => {
  const content = (
    <div>
      <Content
        left
        title="Data-driven"
        text={
          'HumanBase applies machine learning algorithms to learn biological associations' +
          ' from massive genomic data collections. These integrative analyses reach beyond' +
          ' existing "biological knowledge" represented in the literature to identify novel,' +
          ' data-driven associations.'
        }
        image={
          <Image
            src="/heatmap.png"
            responsive
            alt="data heatmap"
            height="250px"
          />
        }
      >
        <Link to="/data">
          Genomics data compendium <i className="fa fa-arrow-right" />
        </Link>
      </Content>
      <Content
        left={false}
        title="Tissue-specific"
        text="HumanBase models tissue-specific gene interactions by leveraging experimentally verified tissue expression and gene function to learn from an immense data compendium of diverse tissues and cell-types. The resulting functional networks accurately capture tissue-specific gene function."
        image={
          <Image
            src="/brain-network.png"
            responsive
            alt="brain functional network"
          />
        }
      >
        <Link to="/gene/3553">
          IL1B in mononuclear phagocyte <i className="fa fa-arrow-right" />
        </Link>
      </Content>
    </div>
  );
  return content;
};

export default NetworkContentItems;
