import React from 'react';
import Layout from '../../components/Layout';
import LandingPage from '../../components/LandingPage';

import { TITLE } from '../../settings';

function action() {
  return {
    chunks: ['home'],
    title: `${TITLE}: data-driven predictions of gene function and interactions`,
    component: (
      <Layout headerOptions={{ hideLogo: true }}>
        <LandingPage />
      </Layout>
    ),
  };
}

export default action;
